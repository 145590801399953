<template>
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus
              :title="
                $t(
                  'components.riderDetailsManagement.diveIntoTab.notifications.headline'
                )
              "
              :hide-plus="true"
            />
          </div>
        </div>
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :headers="getTableHeaders"
          :endpoint="endpoint"
          :qso="getQso"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" text-fallback-always>
                  <FSTableRowItem :text="item.id" :truncate="-5" />
                  <FSTableRowItem class="word-wrap" :text="item.title" />
                  <FSTableRowItem class="word-wrap" :text="item.message" />
                  <FSTableRowItem :text="item.created_at" date />
                  <FSTableRowItem>
                    <template
                      v-if="
                        deliveredStatusMap[item.email_trigger_status] ===
                          'delivered'
                      "
                    >
                      <i class="text-green-500 fas fa-check-circle" />
                    </template>

                    <template
                      v-else-if="
                        deliveredStatusMap[item.email_trigger_status] ===
                          'failed'
                      "
                    >
                      <span class="text-red-600">Failed</span>
                      <i
                        class="ml-2 text-oRed fas fa-exclamation-circle"
                        v-tooltip="
                          `<div class='max-w-xs p-1 text-xs'>${item.email_failure_reason}</div>`
                        "
                      />
                    </template>

                    <span v-else>
                      {{ textFallback }}
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <template
                      v-if="
                        deliveredStatusMap[item.sms_trigger_status] ===
                          'delivered'
                      "
                    >
                      <i class=" text-green-500 fas fa-check-circle" />
                    </template>

                    <template
                      v-else-if="
                        deliveredStatusMap[item.sms_trigger_status] === 'failed'
                      "
                    >
                      <span class="text-red-600">Failed</span>
                      <i
                        class="ml-2 text-oRed fas fa-exclamation-circle"
                        v-tooltip="
                          `<div class='max-w-xs p-1 text-xs'>${item.sms_failure_reason}</div>`
                        "
                      />
                    </template>

                    <span v-else>
                      {{ textFallback }}
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <template
                      v-if="
                        deliveredStatusMap[item.push_trigger_status] ===
                          'delivered'
                      "
                    >
                      <i class=" md:text-green-500 fas fa-check-circle" />
                    </template>

                    <template
                      v-else-if="
                        deliveredStatusMap[item.push_trigger_status] ===
                          'failed'
                      "
                    >
                      <span class="text-red-600">Failed</span>
                      <i
                        class="ml-2 text-oRed fas fa-exclamation-circle"
                        v-tooltip="
                          `<div class='max-w-xs p-1 text-xs'>${item.push_failure_reason}</div>`
                        "
                      />
                    </template>

                    <span v-else>
                      {{ textFallback }}
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <MoreActionsDropdown
                      :primary-key="itemIndex"
                      :actionNotifyRiderEnabled="true"
                      :rawData="getFormattedData(item)"
                      @refresh="refreshPage"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.id" :truncate="-5" />
                  <FSTableRowItem class="word-wrap" :text="item.title" />
                  <FSTableRowItem>
                    <MoreActionsDropdown
                      :primary-key="itemIndex"
                      :actionNotifyRiderEnabled="true"
                      :rawData="getFormattedData(item)"
                      @refresh="refreshPage"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Delivery Time</div>
                      <div class="col-span-5 right-text word-wrap">
                        {{ getUTCAwareTime(item.created_at) || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Message</div>
                      <div class="col-span-5 right-text word-wrap">
                        {{ item.message }}
                      </div>
                    </div>

                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Email</div>
                      <div class="col-span-5 right-text">
                        <template
                          v-if="
                            deliveredStatusMap[item.email_trigger_status] ===
                              'delivered'
                          "
                        >
                          <i class=" text-green-500 fas fa-check-circle" />
                        </template>

                        <template
                          v-else-if="
                            deliveredStatusMap[item.email_trigger_status] ===
                              'failed'
                          "
                        >
                          <span class="text-red-600">Failed</span>
                          <i
                            class="ml-2 text-oRed fas fa-exclamation-circle"
                            v-tooltip="
                              `<div class='max-w-xs p-1 text-xs'>${item.email_failure_reason}</div>`
                            "
                          />
                        </template>

                        <span v-else>
                          {{ textFallback }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">SMS</div>
                      <div class="col-span-5 right-text">
                        <template
                          v-if="
                            deliveredStatusMap[item.sms_trigger_status] ===
                              'delivered'
                          "
                        >
                          <i class=" text-green-500 fas fa-check-circle" />
                        </template>

                        <template
                          v-else-if="
                            deliveredStatusMap[item.sms_trigger_status] ===
                              'failed'
                          "
                        >
                          <span class="text-red-600">Failed</span>
                          <i
                            class="ml-2 text-oRed fas fa-exclamation-circle"
                            v-tooltip="
                              `<div class='max-w-xs p-1 text-xs'>${item.sms_failure_reason}</div>`
                            "
                          />
                        </template>

                        <span v-else>
                          {{ textFallback }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Push</div>
                      <div class="col-span-5 right-text">
                        <template
                          v-if="
                            deliveredStatusMap[item.push_trigger_status] ===
                              'delivered'
                          "
                        >
                          <i class=" text-green-500 fas fa-check-circle" />
                        </template>

                        <template
                          v-else-if="
                            deliveredStatusMap[item.push_trigger_status] ===
                              'failed'
                          "
                        >
                          <span class="text-red-600">Failed</span>
                          <i
                            class="ml-2 text-oRed fas fa-exclamation-circle"
                            v-tooltip="
                              `<div class='max-w-xs p-1 text-xs'>${item.push_failure_reason}</div>`
                            "
                          />
                        </template>

                        <span v-else>
                          {{ textFallback }}
                        </span>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </section>
</template>

<script>
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { getUTCAwareTime } from '@/utils'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
export default {
  name: 'ReportIndex',

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    MoreActionsDropdown,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      fstId: 'rider-custom-notification',
      endpoint: useEndpoints.rider.customNotifications(),
      textFallback: '--',
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.id'
          ),
          width: '8%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.title'
          ),
          width: '12%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.description'
          ),
          width: '25%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.deliveryTime'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.email'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.sms'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.push'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.action'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.id'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.title'
          ),
          width: '40%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.notifications.table.columns.action'
          ),
          width: '30%',
          sort: null,
        },
      ],
      opened: [],
      deliveredStatusMap: {
        0: 'not-triggered',
        1: 'delivered',
        2: 'failed',
      },
    }
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getQso() {
      return {
        append: `&rider=${this.$route.params.id}`,
        prepend: '?',
      }
    },
  },

  methods: {
    getUTCAwareTime,

    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getFormattedData(item) {
      const modifiedData = {
        id: item.rider.id,
        full_name: item.rider.full_name,
        phone_number: item.rider.phone_number,
        title: item.title,
        message: item.message,
      }
      return modifiedData
    },
    refreshPage() {
      setTimeout(() => {
        this.$store.dispatch('fsTable/fetchData')
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.word-wrap {
  max-width: 200px;
  word-wrap: break-word;
}
</style>
