<template>
  <div class="bg-white rounded">
    <div class="grid grid-cols-1 py-5 px-3 md:grid-cols-2">
      <div>
        <TitlePlus
          :title="
            $t('components.riderDetailsManagement.diveIntoTab.logs.headline')
          "
          :hide-plus="true"
          class="pl-2"
        />
      </div>

      <div class="flex md:justify-end">
        <date-range-picker
          :time-filter-enabled="true"
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>
    </div>

    <template>
      <FSTable
        :fst-id="fstId"
        :endpoint="getEndpoint"
        :isExportEnabled="false"
        :searchEnabled="false"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>
        <template #default="{ data }">
          <TimelineGroup
            v-if="data.length > 0"
            :logs="data"
            timeline-type="rider"
          />
        </template>
      </FSTable>
    </template>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import {
  FSTable,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'
import TitlePlus from '@/components/ui/TitlePlus'
export default {
  name: 'ViewRiderLogs',

  components: {
    FSTable,
    FSTableFilter,
    TimelineGroup,
    TitlePlus,
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.rider.logs(this.reqId)
    },
  },
  data() {
    return {
      fstId: 'rider-logs',
      filterOptions: [
        {
          key: 'request_title',
          type: 'checkbox',
          input: [
            { text: 'Unlock', value: 'UNLOCK' },
            { text: 'Lock', value: 'LOCK' },
            { text: 'Guest Add', value: 'GUEST_ADD' },
            { text: 'Parking Photo Upload', value: 'PARKING_PHOTO_UPLOAD' },
            { text: 'Complete Trip', value: 'COMPLETE_TRIP' },
            { text: 'Good Feedback', value: 'GOOD_FEEDBACK' },
            { text: 'Bad Feedback', value: 'BAD_FEEDBACK' },
            { text: 'Pause', value: 'PAUSE' },
            { text: 'Unpause', value: 'UNPAUSE' },
            { text: 'Lock for Pause', value: 'LOCK_FOR_PAUSE' },
            { text: 'Unlock for Unpause', value: 'UNLOCK_FOR_UNPAUSE' },
            { text: 'Lock Status', value: 'LOCK_STATUS' },
            { text: 'Pause Status', value: 'PAUSE_STATUS' },
            { text: 'Nearby Areas', value: 'NEARBY_AREAS' },
            { text: 'Nearby Vehicles', value: 'NEARBY_VEHICLES' },
            { text: 'Reserve Vehicle', value: 'RESERVE_VEHICLE' },
            { text: 'Invoice Refund', value: 'INVOICE_REFUND' },
            { text: 'Report', value: 'REPORT' },
            { text: 'Locked For Pause', value: 'LOCKED_FOR_PAUSE' },
            { text: 'Locked', value: 'LOCKED' },
            { text: 'Unlocked For Pause', value: 'UNLOCKED_FOR_PAUSE' },
            { text: 'Unlocked', value: 'UNLOCKED' },

            {
              text: 'Sign Up',
              value: 'SIGN_UP',
            },
            {
              text: 'Guest Sign Up',
              value: 'GUEST_SIGN_UP',
            },
            {
              text: 'Login',
              value: 'LOGIN',
            },
            {
              text: 'Send SMS',
              value: 'SEND_SMS',
            },
            {
              text: 'Document Submit',
              value: 'DOCUMENT_SUBMIT',
            },
            {
              text: 'Logout',
              value: 'LOGOUT',
            },
            {
              text: 'Balance Adjust',
              value: 'BALANCE_ADJUST',
            },
            {
              text: 'Positive Balance Adjust',
              value: 'POSITIVE_BALANCE_ADJUST',
            },
            {
              text: 'Negative Balance Adjust',
              value: 'NEGATIVE_BALANCE_ADJUST',
            },
            {
              text: 'Default Payment Source Set',
              value: 'DEFAULT_PAYMENT_SOURCE_SET',
            },
            {
              text: 'Default Card Set',
              value: 'DEFAULT_CARD_SET',
            },
            {
              text: 'Card Add',
              value: 'CARD_ADD',
            },
            {
              text: 'Redirection',
              value: 'REDIRECTION',
            },
            {
              text: 'Redirection Topup',
              value: 'REDIRECT_TOPUP',
            },
            {
              text: 'Direct Topup',
              value: 'DIRECT_TOPUP',
            },
            {
              text: 'Card Delete',
              value: 'CARD_DELETE',
            },
            {
              text: 'Redirect Pass Subscribe',
              value: 'REDIRECT_PASS_SUBSCRIBE',
            },
            {
              text: 'Direct Pass Subscribe',
              value: 'DIRECT_PASS_SUBSCRIBE',
            },
            {
              text: 'Promo Apply',
              value: 'PROMO_APPLY',
            },
            {
              text: 'Voucher Apply',
              value: 'VOUCHER_APPLY',
            },
            {
              text: 'Vehicle Outside Geofence',
              value: 'VEHICLE_OUTSIDE_GEOFENCE',
            },
            {
              text: 'Vehicle Inside Restricted Area',
              value: 'VEHICLE_INSIDE_RESTRICTED_AREA',
            },
            {
              text: 'Vehicle Inside Slowzone Area',
              value: 'VEHICLE_INSIDE_SLOWZONE_AREA',
            },
            {
              text: 'Trigger Payment by System',
              value: 'TRIGGER_PAYMENT_BY_SYSTEM',
            },
            {
              text: 'Vehicle Inside Geofence',
              value: 'VEHICLE_INSIDE_GEOFENCE',
            },
            {
              text: 'Vehicle Outside Restricted Area',
              value: 'VEHICLE_OUTSIDE_RESTRICTED_AREA',
            },
            {
              text: 'Vehicle Outside Slowzone Area',
              value: 'VEHICLE_OUTSIDE_SLOWZONE_AREA',
            },
          ],
          title: 'Category',
        },
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Success', value: 'succeeded' },
            { text: 'Failed', value: 'failed' },
          ],
          title: 'Status',
        },
      ],
    }
  },
  methods: {
    onApplyFilterDateRange,
  },
}
</script>

<style lang="scss" scoped></style>
